let context

export function tone(freq) {
  note(freq, 1.8, 'sine', 0.02)
  note(freq, 1.2, 'sine', 0.05)
  note(freq, 0.9, 'sine', 0.4)
  note(freq, 0.2, 'square', 0.1)
}

export function note(freq, duration, type, volume) {
  if (!context) {
    const AudioContext = window.AudioContext || window.webkitAudioContext
    if (!AudioContext) return
    context = new AudioContext()
  }

  const gain = context.createGain()
  gain.connect(context.destination)

  const oscillator = context.createOscillator()
  oscillator.type = type
  oscillator.frequency.value = freq
  oscillator.connect(gain)

  const now = context.currentTime
  const end = now + duration

  gain.gain.setValueAtTime(0.00001, now)
  gain.gain.exponentialRampToValueAtTime(volume, now + 0.02)
  gain.gain.exponentialRampToValueAtTime(0.00001, end)

  oscillator.start()
  oscillator.stop(end + 0.1)

  setTimeout(() => {
    oscillator.disconnect()
    gain.disconnect()
  }, duration * 1000 + 500)
}
