<script>
  import { ripple } from './ripple'
  import { tone } from './tone'
  const rippleOptions = {
    speed: 200,
    color: 'hsla(281, 73%, 29%, 0.6)',
  }
  const onClick = () => {
    tone(440)
  }
</script>

<main use:ripple="{rippleOptions}" on:click="{onClick}">
  <span class="intro">
    Tap along to the tones to estimate your device’s audio delay.
  </span>
  <span class="start">
    Tap anywhere<br />
    to start
  </span>
</main>

<style>
  main {
    width: 100vw;
    height: 100vh;
    position: relative;
    color: hsl(58, 60%, 70%);
    background-color: hsl(240, 50%, 38%);
    overflow: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    padding: 2rem;
    display: grid;
    grid-row-gap: 2rem;
  }
  span {
    font-size: 2.25rem;
    letter-spacing: 0.1rem;
    line-height: 3rem;
    text-align: center;
  }
  .intro {
    font-weight: 200;
    align-self: end;
  }
  .start {
    font-weight: 500;
    align-self: center;
  }
  @media (max-width: 320px) {
    main {
      padding: 1rem;
      grid-row-gap: 1rem;
    }
    span {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
</style>
