export function add(array, item) {
  return [...array, item]
}

export function remove(array, item) {
  const index = array.indexOf(item)
  if (index < 0) return array
  const head = array.slice(0, index)
  const tail = array.slice(index + 1)
  return [...head, ...tail]
}
