<script>
  import { add, remove } from './utils'

  let id = 0
  let ripples = []

  export const addRipple = ({ size, left, top, color, duration }) => {
    id += 1
    const style = `
      left: ${left}px;
      top: ${top}px;
      width: ${size}px;
      height: ${size}px;
      animation-duration: ${duration || 1000}ms;
      background-color: ${color || 'hsla(0, 0%, 100%, 0.8)'};`

    const ripple = { id, style }
    ripples = add(ripples, ripple)

    setTimeout(() => {
      ripples = remove(ripples, ripple)
    }, duration + 100)
  }
</script>

<div>
  {#each ripples as ripple (ripple.id)}
  <span style="{ripple.style}"></span>
  {/each}
</div>

<style>
  div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    pointer-events: none;
  }
  span {
    position: absolute;
    transform: scale(0);
    border-radius: 100%;
    animation-name: ripple;
    animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  }
  @keyframes ripple {
    to {
      opacity: 0;
      transform: scale(2);
    }
  }
</style>
