import Ripples from './Ripples.html'

// Note: `speed` is in (average) pixels per second
export function ripple(node, options) {
  let speed = (options && options.speed) || 100
  let color = (options && options.color) || 'hsla(0, 0%, 100%, 0.8)'

  if (window.getComputedStyle(node).position === 'static') {
    console.warn('Setting ripple target position to "relative".')
    node.style.position = 'relative'
  }

  const ripples = new Ripples({ target: node })

  function onTap(event) {
    const { pageX, pageY } = event.touches ? event.touches[0] : event
    const { x, y, width, height } = node.getBoundingClientRect()
    const size = Math.round(Math.max(width, height))
    const left = Math.round(pageX - x - size / 2)
    const top = Math.round(pageY - y - size / 2)
    const duration = (size / 2 / speed) * 1000

    ripples.addRipple({ size, left, top, duration, color })
  }

  node.addEventListener('mousedown', onTap)
  node.addEventListener('touchstart', onTap)

  return {
    update(options) {
      speed = (options && options.speed) || 100
      color = (options && options.color) || 'hsla(0, 0%, 100%, 0.8)'
    },
    destroy() {
      node.removeEventListener('mousedown', onTap)
      node.removeEventListener('touchstart', onTap)
      ripples.$destroy()
    },
  }
}
